<template>
  <div class="feedback__page">
    <div class="feedback__page__container">
      <b-row cols="d-flex align-items-center my-3 text__phone">
        <h3 class="mx-50 text-capitalize m-0 text__phone__h-3">
          What Abilities you know about {{ decodeURIComponent(name) }}
        </h3>
        <div class="icons__phone d-flex mx-50">
          <div
            v-b-tooltip.hover.bottom="'Reset All'"
            id="pi-step-0"
            @click.stop="
              selectedPersonalities.length && !isResetDisablePermanently > 0
                ? resetPersonalityTraits()
                : ''
            "
            variant="flat-success"
          >
            <img
              class="icon-color"
              :class="
                selectedPersonalities.length > 0 && !isResetDisablePermanently
                  ? 'cursor-pointer'
                  : 'icon-color-opacity'
              "
              width="20px"
              src="@/assets/images/icons/reset_icon.svg"
              alt="Reset"
            />
          </div>
          <!--- Only Reset Priority --->
          <!-- <div
          id="pi-step-1"
          v-b-tooltip.hover.bottom="'Only Reset Priority'"
          @click.stop="onlyResetPriorityPersonalityTraits()"
          icon-pack="material-icons"
        >
          <img
            class="icon-color"
            width="20px"
            src="@/assets/images/icons/low_priority.svg"
          /></div
      > -->
        </div>
      </b-row>

      <endorese-abilities
        class="w-1/2"
        :userDetails="{ token, name }"
        :userSelfAssessment="true"
        :resetPersonality="resetUserPersonality"
        :resetPriority="resetPriority"
      />
    </div>
  </div>
</template>

<script>
import EndoreseAbilities from "./components/EndoreseAbilities.vue";
import { BRow, VBTooltip } from "bootstrap-vue";
import notificationMixin from "@/mixins/notificationMixin.js";

export default {
  components: { EndoreseAbilities, BRow },
  mixins: [notificationMixin],
  data() {
    return {
      resetUserPersonality: false,
      resetPriority: false,
      token: "",
      name: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    selectedPersonalities() {
      return this.$store.state.user.selectedPersonalities;
    },
    resetUserAbility() {
      return this.$store.state.user.resetUserAbility;
    },
    isResetDisablePermanently() {
      return this.$store.state.user.isResetDisablePermanently;
    },
  },
  methods: {
    resetPersonalityTraits() {
      this.$store.commit("user/RESET_USER_ABILITIES", true);
    },
    // onlyResetPriorityPersonalityTraits() {
    //   this.resetPriority = true;
    // },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.name = this.$route.query.sender;
  },
  created() {
    this.$store
      .dispatch("user/getFeedbackDetails", this.$route.query.token)
      .then((res) => {
        let feedBackGiven =
          res && res.data && res.data[0] && res.data[0].endorsementStatus
            ? res.data[0].endorsementStatus
            : "";
        if (feedBackGiven === "COMPLETED") {
          this.$router.push("/feedback-given");
        }
      })
      .catch(() => {
        // console.log(err);
        return;
      });
  },
};
</script>

<style lang="scss" scoped>
.feedback__page {
  height: 100vh;
  &__container {
    width: 65%;
    margin: 0 auto;
  }
}
.icon-color {
  filter: invert(96%) sepia(59%) saturate(4306%) hue-rotate(320deg)
    brightness(96%) contrast(97%);
  &-opacity {
    opacity: 0.5;
  }
}
@media only screen and (max-width: 1024px) {
  .feedback__page__container {
    width: 80%;
  }
}
@media only screen and (max-width: 991px) {
  .feedback__page__container {
    width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  .feedback__page {
    padding: 1em 2em;
    &__container {
      width: 100%;
    }
    .text__phone {
      margin-bottom: 1em !important ;
      &__h-3 {
        font-size: 1.25em;
      }
    }
    .icons__phone {
      width: 100%;
      justify-content: flex-end;
    }
  }
}
</style>
