<template>
  <div :class="`abilities--light-theme`">
    <b-row>
      <!--  selected personality traits  -->
      <!-- best suitable abilities for user -->
      <b-col class="ml-50" md="12">
        <h5 class="" v-if="selectedPersonalityData.length < 6">
          {{
            $t("editor.endorsement.chooseYourTop6Strengths.p1", {
              name: userDetails.name,
            })
          }}
          <span
            ><b class="text-success">{{
              $t("editor.endorsement.chooseYourTop6Strengths.p2")
            }}</b></span
          >
          {{ $t(`editor.endorsement.chooseYourTop6Strengths.p3`) }}
        </h5>

        <h5 v-else class="font-weight-bolder">
          {{
            $t("editor.endorsement.characteristicsThatBestDescribeYou.p1", {
              name: userDetails.name,
            })
          }}
          <span class="text-success"
            ><b>{{
              $t("editor.endorsement.characteristicsThatBestDescribeYou.p2")
            }}</b></span
          >
          {{ $t("editor.endorsement.characteristicsThatBestDescribeYou.p3") }}
        </h5></b-col
      >
      <b-col
        md="4"
        cols="12"
        v-for="(personality, index) in selectedPersonalityData.slice(0, 6)"
        :key="index + 'best'"
        v-b-tooltip.hover.right="personality.description"
      >
        <div
          class="ability__detail m-50 p-50 d-flex align-items-center cursor-pointer ability--best"
        >
          <div class="ability__icon">
            <img class="ability__icon__img" :src="personality.pngIcon" alt="" />
          </div>
          <div class="ability__name ml-1 h5 font-weight-bold">
            {{ personality.title }}
          </div>
        </div>
      </b-col>
      <!-- most suitable abilities for user  -->
      <b-col class="ml-50" md="12" v-show="selectedPersonalityData.length >= 6">
        <h5
          class="font-weight-bolder"
          v-if="selectedPersonalityData.length < 12"
        >
          {{
            $t("editor.endorsement.choose6MoreStrengths.p1", {
              name: userDetails.name,
            })
          }}
          <span class="text-primary"
            ><b>{{ $t("editor.endorsement.choose6MoreStrengths.p2") }}</b></span
          >
          {{ $t("editor.endorsement.choose6MoreStrengths.p3") }}
        </h5>

        <h5 v-else class="font-weight-bolder">
          {{
            $t("editor.endorsement.characteristicsThatMostDescribeYou.p1", {
              name: userDetails.name,
            })
          }}
          <span class="text-primary"
            ><b>{{
              $t("editor.endorsement.characteristicsThatMostDescribeYou.p2")
            }}</b></span
          >
          {{ $t("editor.endorsement.characteristicsThatMostDescribeYou.p3") }}
        </h5></b-col
      >
      <b-col
        md="4"
        cols="12"
        v-for="(personality, index) in selectedPersonalityData.slice(6, 12)"
        :key="index + 'most'"
        v-b-tooltip.hover.right="personality.description"
      >
        <div
          class="ability__detail m-50 p-50 d-flex align-items-center cursor-pointer ability--most"
        >
          <div class="ability__icon">
            <img class="ability__icon__img" :src="personality.pngIcon" alt="" />
          </div>
          <div class="ability__name ml-1 h5 font-weight-bold">
            {{ personality.title }}
          </div>
        </div>
      </b-col>
      <!-- least suitable abilities for user  -->
      <b-col
        class="ml-50"
        md="12"
        v-show="selectedPersonalityData.length >= 12"
      >
        <!-- <h5
          class="font-weight-bolder"
          v-if="selectedPersonalityData.length < 18"
        >
          {{ $t("editor.endorsement.choose6LeastStrengths.p1") }}
          <span class="text-warning"
            ><b>{{
              $t("editor.endorsement.choose6LeastStrengths.p2")
            }}</b></span
          >
          {{ $t("editor.endorsement.choose6LeastStrengths.p3") }}
        </h5> -->

        <h5 class="font-weight-bolder">
          {{
            $t("editor.endorsement.characteristicsThatLeastDescribeYou.p1", {
              name: userDetails.name,
            })
          }}
          <span class="text-warning"
            ><b>{{
              $t("editor.endorsement.characteristicsThatLeastDescribeYou.p2")
            }}</b></span
          >
          {{ $t("editor.endorsement.characteristicsThatLeastDescribeYou.p3") }}
        </h5></b-col
      >
      <b-col
        md="4"
        cols="12"
        v-for="(personality, index) in selectedPersonalityData.slice(12, 18)"
        :key="index + 'least'"
        v-b-tooltip.hover.right="personality.description"
      >
        <div
          class="ability__detail m-50 p-50 d-flex align-items-center cursor-pointer ability--least"
        >
          <div class="ability__icon">
            <img class="ability__icon__img" :src="personality.pngIcon" alt="" />
          </div>
          <div
            class="ability__name w-100 mx-50 h5 font-weight-bold d-flex justify-content-between align-items-center"
          >
            <span>{{ personality.title }}</span>
            <!-- <span
              class="d-flex align-items-center justify-content-center priority-number"
            >
              {{ index + 1 }}</span
            > -->
          </div>
        </div>
      </b-col>

      <!-- non-selected personality traits  -->
      <b-col
        md="4"
        cols="12"
        v-show="shuffledPersonalityData.length > 0"
        v-for="(personality, index) in shuffledPersonalityData"
        :key="index + 'avoid-duplicacy'"
        v-b-tooltip.hover.right="personality.description"
        @click.stop="selectPersonality(personality, index)"
      >
        <div
          class="ability__detail m-50 p-50 d-flex align-items-center cursor-pointer"
        >
          <div class="ability__icon">
            <img class="ability__icon__img" :src="personality.pngIcon" alt="" />
          </div>
          <div class="ability__name ml-75 h5 font-weight-bold">
            {{ personality.title }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import personalityData from "@/assets/data/personalityTraits";
import { VBTooltip, BRow, BCol } from "bootstrap-vue";
import notificationMixin from "@/mixins/notificationMixin.js";

export default {
  mixins: [notificationMixin],
  components: {
    BCol,
    BRow,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      personalityData,
      shuffledPersonalityData: [],
      selectedPersonalityData: [],
      hoveredAbility: "",
      level: "",
      // abilitiesObj: {},
      abilitiesArray: [],
      priorityObj: {},
    };
  },
  props: {
    userDetails: Object,
  },
  computed: {
    currentTheme() {
      return this.$store.state.user.themeType;
    },
    resetUserAbility() {
      return this.$store.state.user.resetUserAbility;
    },
  },
  watch: {
    resetUserAbility(newVal) {
      if (newVal === true) {
        this.resetNotification();
        this.selectedPersonalityData = [];
        this.abilitiesArray = [];
        this.$store.commit("user/RESET_USER_ABILITIES", false);
        this.shufflePersonalityData([...this.personalityData]);
      }
    },
    shuffledPersonalityData(newVal) {
      if (newVal.length < 7) {
        // this.level = 0;
        for (let i = 0; i < newVal.length; i++) {
          this.abilitiesArray.push(newVal[i].mapId);
          // this.abilitiesObj[newVal[i].mapId] = this.level;
        }
        return;
      }
    },
    selectedPersonalityData(newVal) {
      //for count only
      this.$store.commit("user/SET_SELECTED_PERSONALITIES", newVal);
      //save user assessment
      if (newVal.length === 12 && this.abilitiesArray.length === 18) {
        let notifications =
          newVal.length === 0
            ? this.resetNotification
            : this.assessmentNotification;

        const payload = {
          userId: this.userDetails.token,
          abilities: this.abilitiesArray,
        };
        this.$store
          .dispatch("user/addEndorsement", payload)
          .then(() => {
            this.$store.commit("user/DISABLE_RESET_PERMANENTLY", true);
            notifications();
          })
          .catch((e) => {
            console.log(e);
            if (
              e.response.data.errors[0].msg.includes("Duplicate Endorsement")
            ) {
              let notificationDetail = {
                message: e.response.data.errors[0].msg,
                variant: "danger",
              };
              this.showToast(notificationDetail);
            } else {
              let notificationDetail = {
                message: this.messages.error.unknown_error,
                variant: "danger",
              };
              this.showToast(notificationDetail);
            }
          });
      } else if (this.selectedPersonalityData.length === 6) {
        let notificationDetail = {
          title: this.messages.abilitySelection.chooseMoreAbility.title,
          message: this.messages.abilitySelection.chooseMoreAbility.text(
            this.userDetails.name
          ),
          variant: "info",
        };
        this.showToast(notificationDetail);
      } else if (newVal.length === 18) {
        this.savePriority();
      }
    },
  },
  methods: {
    shufflePersonalityData(array) {
      for (let i = array.length - 1; i > 0; i--) {
        // Generate random number for index
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return (this.shuffledPersonalityData = array);
    },
    pushPersonality(personality, index) {
      // if (this.selectedPersonalityData.length < 6) {
      //   this.level = 2;
      // } else if (this.selectedPersonalityData.length <= 11) {
      //   this.level = 1;
      // } else {
      //   this.level = 0;
      // }
      this.shuffledPersonalityData.splice(index, 1);
      this.selectedPersonalityData.push(personality);
      this.abilitiesArray.push(personality.mapId);
      // this.abilitiesObj[personality.mapId] = this.level;
    },
    selectPersonality(personality, index) {
      if (this.selectedPersonalityData.length === 0) {
        this.pushPersonality(personality, index);
      } else {
        for (let i = 0; i <= this.selectedPersonalityData.length; i++) {
          if (this.selectedPersonalityData[i] === personality) {
            return;
          } else {
            this.pushPersonality(personality, index);
            return;
          }
        }
      }
    },
    savePriority() {
      return;
    },
    resetNotification() {
      let notificationDetailSec = {
        message: this.messages.resetAbilitiy.text(this.userDetails.name),
        variant: "success",
      };
      this.showToast(notificationDetailSec);
    },
    assessmentNotification() {
      let notificationDetail = {
        message: this.messages.endorsementCompletion.text(
          this.userDetails.name
        ),
        variant: "success",
      };
      this.showToast(notificationDetail);

      setTimeout(
        (self) => {
          self.$router.push({ path: "/thank-you" });
        },
        3500,
        this
      );
    },
  },
  mounted() {
    this.shufflePersonalityData([...this.personalityData]);
  },
};
</script>
